var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tradingCenter-list"},[_c('h1',{class:[_vm.token ? 'activeT' : 'title']},[_vm._v(" 欢迎进入"+_vm._s(_vm.platformName)+"开标大厅 ")]),_c('div',{staticClass:"container"},[_c('Co-list',{staticClass:"table",attrs:{"tableData":_vm.data,"tableColumns":_vm.tableColumns,"selection":false,"border":false,"numLabel":"序号"},scopedSlots:_vm._u([{key:"stateName",fn:function(data){return _c('div',{staticClass:"slot"},[_c('span',{staticClass:"d",style:({
            background:
              data.data.stateName === '未开标'
                ? '#FF7171'
                : data.data.stateName === '已开标'
                ? '#3AC848'
                : '#B4B4B4'
          })}),_c('span',{style:({
            color:
              data.data.stateName === '未开标'
                ? '#FF7171'
                : data.data.stateName === '已开标'
                ? '#3AC848'
                : '#B4B4B4'
          })},[_vm._v(_vm._s(data.data.stateName))])])}},{key:"op",fn:function(data){return _c('div',{},[_c('el-button',{attrs:{"type":"text","disabled":new Date(data.data.meetingStartDate).getTime() >
              new Date().getTime()},on:{"click":function($event){return _vm.fun_GetInfo(data.data)}}},[_vm._v("进入")])],1)}}])}),_c('el-button',{on:{"click":_vm.fun_GoBack}},[_vm._v("返回")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }